import clevertap from '.'
export const ctEvent = {
  login: 'Login',
  signup: 'Sign Up',
  addToCart: 'Add to Cart',
  charged: 'Charged',
  viewItem: 'View Item',
  cartView: 'Cart View',
  applyMembershipCard: 'Apply Membership Card',
  webSessionStarted: 'Web Session Started',
}

export const ctEventPush = (eventName, payload) => {
  clevertap.event.push(eventName, payload)
}
